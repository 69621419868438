<!--
 * @Author: Haoxiwei 1078734933@qq.com
 * @Date: 2023-04-27 11:17:12
 * @LastEditors: Haoxiwei 1078734933@qq.com
 * @LastEditTime: 2024-07-11 14:22:23
 * @FilePath: /wemeet-admin-new/src/views/channel/components/addContact.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="800px" @close="close">
    <el-form :model="queryForm" :rules="rules" ref="formRef">
      <el-form-item label='姓名' label-width='100px' prop="name">
        <el-input placeholder="请输入用户姓名" v-model="queryForm.name"></el-input>
      </el-form-item>
      <el-form-item label='区号' label-width='100px' prop="phoneCountryCode">
        <el-select v-model="queryForm.phoneCountryCode" placeholder="请选择地区">
          <el-option v-for="(item, index) in counrtyList" :key="item.countryPhoneCode" :label="item.country + item.countryPhoneCode" :value="item.countryPhoneCode"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label='手机号' label-width='100px' prop="phone">
        <el-input v-model="queryForm.phone"></el-input>
      </el-form-item>

    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import { countryQueryInfo } from '@/api/brand'
import { channelDistribution } from '@/api/channel'

export default defineComponent({
  name: 'editRelated',
  emits: ['addUserInfo'],
  props: {
    channelId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      size: 'large',
      tableLayout: 'auto',
      title: '添加联系人',
      dialogFormVisible: false,
      list: [],
      hostInfo: [],
      phone: '',
      code: '',
      counrtyList: [],
      queryForm: { phone: '', phoneCountryCode: '', name: '' },
      formRef: null,
      rules: {
        phoneCountryCode: [
          { required: true, trigger: 'change', message: '请选择区号' },
        ],
        phone: [{ required: true, trigger: 'blur', message: '请输入手机号' }],
        name: [{ required: true, trigger: 'blur', message: '请输入姓名' }],
      },
    })

    const showEdit = async (row) => {
      // 查询所有国家列表
      const { data: country } = await countryQueryInfo()
      console.log(country)
      state.counrtyList = country
      state.dialogFormVisible = true
    }
    const close = () => {
      state.queryForm = { phone: '', phoneCountryCode: '', name: '' }
      state.hostInfo = []
      state.dialogFormVisible = false
    }

    const save = async () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          state.queryForm.channelId = props.channelId
          try {
            const { data } = await channelDistribution(state.queryForm)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            close()
          } catch (error) {}
        }
      })
    }

    const handleSelectionChange = (val) => {
      state.hostInfo = val
    }
    return {
      ...toRefs(state),
      showEdit,
      close,
      save,
      handleSelectionChange,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}

.userInfoBox {
  display: flex;
  justify-content: left;
  background: #67c23a;
  padding: 10px;
  box-sizing: border-box;
  .detailInfo {
    margin-left: 20px;
    color: #fff;
    font-size: 16px;
  }
}
</style>
